/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
/**
 * Module for header on InventoryRequestDetailCatalog Page
 * @module src/SurgicalDetail/InventoryRequestDetailCatalog
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC, ReactNode, useCallback, useState, useEffect } from 'react';
import Input from '@salesforce/design-system-react/components/input';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Button from '@salesforce/design-system-react/components/button';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Icon from '@salesforce/design-system-react/components/icon';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import CartComponent from './cartComponent';
import './index.scss';
import InventoryRequestAddItems from '../InventoryRequest/InventoryRequestAddItems';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import {
  CYCLE_COUNT,
  NOTIFY_TYPE,
  EVENT_TRANSFER,
  TRANSFER_ACTIONS,
  TRANSFER_ACTIONS_APPROVAL,
  OPSCSRUSERS,
  MAX_QUANTITY,
  PERSONA_CONDITION_OPS_CSR,
} from '../../../../util/constants';
import { getEventIcon, getHoldIcon } from '../../../../util/utilityFunctions';
import RESUBMIT_ORDER_DETAILS from '../../../../graphql/mutations/resubmitOrderDetails';
// eslint-disable-next-line import/no-named-as-default
import UPDATE_TRANSFER_INVENTORY from '../../../../graphql/mutations/updateTransferInventory';
import SnackBar from '../../../Shared/SnackBar';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import debounced from '../../../../util/debounced';

interface Props {
  inventoryDetail?: any;
  isEventInventoryFlow?: boolean;
  refetch: () => void;
  visualIndicator: string;
  type: string;
  hasEventInventory?: any;
  eventInventoryExternalId?: any;
  externalId?: any;
  isOrder?: boolean;
  transferType: any;
  viewOnly?: boolean;
}

interface DynamicValue {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  value: string;
  __typename?: string;
}

const InventoryRequestTable: FC<Props> = ({
  refetch,
  inventoryDetail,
  isEventInventoryFlow,
  visualIndicator,
  type,
  hasEventInventory,
  eventInventoryExternalId,
  externalId,
  transferType,
  isOrder,
  viewOnly,
}) => {
  const userInfo = useSelector(getUserInfo);
  const { open, notification, openSnackBar } = useSnackBar();
  const [searchValue, setSearchText] = useState('');
  const [isLoading, setLoader] = useState(false);
  const [showSearchCatalogModal, setShowSearchCatalogModal] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [reSubmitInventory] = useMutation(RESUBMIT_ORDER_DETAILS);
  // const [createUpdateUsage, { data: eventUsageMutation }] = useMutation(CREATE_UPDATE_USAGE);
  const [createUpdateInventoryReturn, { data: updateTransferInventory }] = useMutation(
    UPDATE_TRANSFER_INVENTORY
  );

  const onHandleCloseSearchCatalogModal = (): void => {
    setShowSearchCatalogModal(false);
    if (refetch) {
      refetch();
    }
  };
  const tableClass = type === 'Requesting' ? 'irp-cols' : 'irs-colstr';
  const persona = userInfo?.personas?.toLowerCase();
  const isUserOps = !!persona?.includes('branch op');
  const isUserAsr = !!persona?.includes('sales associate');
  const isUserRsm = !!persona?.includes('sales manager');
  const [quantityCount, setCartCount] = useState(
    inventoryDetail?.lineItems?.reduce(
      (accumulator: any, current: { quantity: any }) => accumulator + Number(current.quantity),
      0
    )
  );
  const [quantityTransferCount, setCartTransferCount] = useState(
    inventoryDetail?.transferredLineItems?.reduce(
      (accumulator: any, current: { transferredQuantity: any }) =>
        accumulator + Number(current.transferredQuantity || 0),
      0
    )
  );
  // const isUserOpsCsr = !!OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas);
  const isUserOpsCsr =
    userInfo &&
    PERSONA_CONDITION_OPS_CSR?.some(el => userInfo?.personas?.toLowerCase()?.includes(el));

  useEffect(() => {
    if (type === 'Requesting') {
      const total = items?.reduce(
        (accumulator: any, current: any) => accumulator + Number(current.quantity),
        0
      );
      setCartCount(total);
    } else {
      const total = items?.reduce(
        (accumulator: any, current: any) => accumulator + Number(current.transferredQuantity || 0),
        0
      );
      setCartTransferCount(total);
    }
  }, [items, type]);

  useEffect(() => {
    if (type === 'Requesting') {
      const updateList = inventoryDetail.lineItems.map((record: any) => {
        return {
          ...record,
          changed: false,
          counterValue: Number(record.quantity),
        };
      });
      setItems(updateList);
    } else {
      const updateList = inventoryDetail.transferredLineItems.map((record: any) => {
        return {
          ...record,
          changed: false,
          counterValue: Number(record.quantity),
        };
      });
      setItems(updateList);
    }
    // setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryDetail.lineItems, type, inventoryDetail.transferredLineItems]);

  const onSaveButtonTransfer = (finalItems: any): void => {
    const isAttempted = finalItems.filter((item: any) => item.action === '');
    if (isAttempted.length > 0) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'Please attempt all the inventory');
    } else {
      const updatedPickedItems = finalItems.map((element: any): any => {
        return {
          id: element?.id || '',
          externalId: element.externalId || '',
          lotNumber: element.lotNumber || '',
          productId: element.productId || '',
          quantity: element.quantity || '',
          priceBookEntryId: element.priceBookEntryId || '',
          unitPrice: element.unitPrice || '',
          status: inventoryDetail.status || 'New',
          approvalStatus: element.type || '',
          salesUnitOfMeasure: element.salesUnitOfMeasure || '',
          productNumber: element.productNumber,
          isLotNumber: element.isLotNumber,
        };
      });
      const updatedDetails = {
        orderId: inventoryDetail.id || '',
        orderExternalId: inventoryDetail.externalId || '',
        lineItems: updatedPickedItems || [],
      };
      createUpdateInventoryReturn({
        variables: updatedDetails,
      })
        .then(response => {
          if (
            response &&
            response?.data &&
            response?.data?.updateTransferInventory &&
            response?.data?.updateTransferInventory?.message &&
            response?.data?.updateTransferInventory?.message === 'success'
          ) {
            openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory transfer saved successfully');
            setTimeout(() => {
              refetch();
            }, 500);
          } else {
            // eslint-disable-next-line no-console
            console.log('We had a trouble saving data to salesforce. Please try again later');
          }
        })
        .catch(error => {
          setLoader(false);
          setTimeout(() => {
            if (error && error.message) {
              //    setLoader(false);
              openSnackBar(NOTIFY_TYPE.ERROR, `${error.message}`);
            } else {
              openSnackBar(
                NOTIFY_TYPE.ERROR,
                'Error: We had a trouble saving data to salesforce. Please try again later'
              );
            }
          }, 1000);
        });
    }
  };

  const handleAproveAll = (): void => {
    const updatedPickedItems = items.map((element: any): any => {
      return {
        id: element?.id || '',
        externalId: element.externalId || '',
        lotNumber: element.lotNumber || '',
        productId: element.productId || '',
        quantity: element.quantity || '',
        priceBookEntryId: element.priceBookEntryId || '',
        unitPrice: element.unitPrice || '',
        status: inventoryDetail.status || 'New',
        approvalStatus: 'Approved' || '',
        salesUnitOfMeasure: element.salesUnitOfMeasure || '',
        productNumber: element.productNumber,
        isLotNumber: element.isLotNumber,
      };
    });
    const updatedDetails = {
      orderId: inventoryDetail.id || '',
      orderExternalId: inventoryDetail.externalId || '',
      lineItems: updatedPickedItems || [],
    };
    createUpdateInventoryReturn({
      variables: updatedDetails,
    }).then(response => {
      if (
        response &&
        response?.data &&
        response?.data?.updateTransferInventory &&
        response?.data?.updateTransferInventory?.message &&
        response?.data?.updateTransferInventory?.message === 'success'
      ) {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory transfer saved successfully');
        setTimeout(() => {
          refetch();
        }, 500);
      } else {
        // eslint-disable-next-line no-console
        console.log('We had a trouble saving data to salesforce. Please try again later');
      }
    });
  };
  const handleRejectAll = (): void => {
    const updatedPickedItems = items.map((element: any): any => {
      return {
        id: element?.id || '',
        externalId: element.externalId || '',
        lotNumber: element.lotNumber || '',
        productId: element.productId || '',
        quantity: element.quantity || '',
        priceBookEntryId: element.priceBookEntryId || '',
        unitPrice: element.unitPrice || '',
        status: inventoryDetail.status || 'New',
        approvalStatus: 'Rejected' || '',
        salesUnitOfMeasure: element.salesUnitOfMeasure || '',
        productNumber: element.productNumber,
        isLotNumber: element.isLotNumber,
      };
    });
    const updatedDetails = {
      orderId: inventoryDetail.id || '',
      orderExternalId: inventoryDetail.externalId || '',
      lineItems: updatedPickedItems || [],
    };
    createUpdateInventoryReturn({
      variables: updatedDetails,
    }).then(response => {
      if (
        response &&
        response?.data &&
        response?.data?.updateTransferInventory &&
        response?.data?.updateTransferInventory?.message &&
        response?.data?.updateTransferInventory?.message === 'success'
      ) {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory transfer saved successfully');
        setTimeout(() => {
          refetch();
        }, 500);
      } else {
        // eslint-disable-next-line no-console
        console.log('We had a trouble saving data to salesforce. Please try again later');
      }
    });
  };

  const handleReturnType = (changeItem: any): void => {
    const finalLineItems = items.map((item: any) => {
      // eslint-disable-next-line no-shadow
      const { externalId, approvalStatus } = item;
      return {
        ...item,
        type: changeItem.externalId === externalId ? changeItem.type[0]?.value : approvalStatus,
      };
    });
    setItems(finalLineItems);
    onSaveButtonTransfer(finalLineItems);
  };

  const handleSelectDropdownItem = (data: any, item: any): void => {
    const obj = {
      ...item,
      type: data.selection,
    };
    handleReturnType(obj);
  };

  const calculateActualQuantitity = useCallback((item: any, onHandQuantity: any) => {
    const isPackContent = item?.salesUnitOfMeasure === 'PK' || item?.salesUnitOfMeasure === 'BX';
    if (!isPackContent) {
      return onHandQuantity;
    }
    const packOfContentCount = item?.packContent > 0 ? item?.packContent || 1 : 1;
    if (item?.productFamily === 'SPTSIMPLANTS') {
      return onHandQuantity * packOfContentCount;
    }
    return onHandQuantity;
  }, []);
  const holdIconPath = getHoldIcon();
  const formatLineItemToReSubmit = (lineItemsArray: any): any => {
    const finalLineItems: Array<any> = [];
    lineItemsArray.map(
      (item: {
        productId: any;
        productNumber: any;
        quantity: any;
        actualQuantity: any;
        externalId: any;
        lotNumber: any;
        sfid: any;
        unitPrice: any;
        caseUsageExternalId: any;
        priceBookEntryId: any;
        salesUnitOfMeasure: any;
        deliveredDate: any;
        caseUsageId: any;
        sourceLocation: any;
        isLotNumber: any;
        // eslint-disable-next-line array-callback-return
      }): any => {
        finalLineItems.push({
          caseUsageId: item.caseUsageId,
          caseUsageExternalId: item.caseUsageExternalId,
          priceBookEntryId: item.priceBookEntryId,
          salesUnitOfMeasure: item.salesUnitOfMeasure,
          deliveredDate: item.deliveredDate || null,
          productId: item.productId,
          productNumber: item.productNumber,
          quantity: item.quantity,
          actualQuantity: item.actualQuantity,
          lotNumber: item.lotNumber || '',
          externalId: item.externalId || null,
          sfid: item.sfid || '',
          unitPrice: item.unitPrice || '',
          sourceLocation: item.sourceLocation,
          isLotNumber: item.isLotNumber,
        });
      }
    );
    return finalLineItems;
  };

  const handleUpdates = (): void => {
    // setLoader(true);
    if (transferType === 'Failed') {
      // resubmitInventory();
      const payload: Array<any> = [];
      items.forEach((product: any): void => {
        if (product.changed) {
          const actualQuantity = calculateActualQuantitity(product, product.counterValue);
          payload.push({
            // sfid: product.sfid,
            productId: product.productId,
            quantity: product.counterValue.toString(),
            actualQuantity: actualQuantity.toString(),
            productNumber: product.productNumber,
            priceBookEntryId: product.priceBookEntryId,
            salesUnitOfMeasure: product.salesUnitOfMeasure,
            deliveredDate: null,
            externalId: product?.orderItemId === null ? product?.orderItemExternalId : '',
            sfid: product?.orderItemId,
            caseUsageId: product?.id,
            caseUsageExternalId: product?.externalId,
            ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
            sourceLocation: product?.sourceLocation,
            lotNumber: product?.lotNumber,
            isLotNumber: product?.isLotNumber,
            // ...(product.caseUsageId && { caseUsageId: product.caseUsageId }),
            // ...(product.caseUsageExternalId && {
            //   caseUsageExternalId: product.caseUsageExternalId,
            // }),
          });
        }
      });
      const mutation = {
        id: inventoryDetail?.orderId,
        caseId: inventoryDetail.id,
        caseExternalId: inventoryDetail.externalId,
        // externalId: inventoryDetail.externalId,
        requestedFrom: 'Inventory Transfer',
        submitType: 'Update',
        lineItems: formatLineItemToReSubmit(payload),
        salesRepId: inventoryDetail.salesRepId,
      };
      setLoader(true);
      reSubmitInventory({
        variables: mutation,
      }).then((res: any) => {
        if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
          setTimeout(() => {
            setLoader(false);
            openSnackBar(
              NOTIFY_TYPE.SUCCESS,
              'Line items for Transfer Inventory updated successfully'
            );
          }, 1000);
          refetch();
        }
      });
    } else {
      const payload: Array<any> = [];
      items?.forEach((product: any): void => {
        if (product.changed) {
          payload.push({
            id: product.id,
            productId: product.productId,
            externalId: product.externalId,
            trackingNumber: product.trackingNumber,
            lotNumber: product?.lotNumber,
            quantity: product.counterValue.toString(),
            productNumber: product.productNumber,
            priceBookEntryId: product.priceBookEntryId,
            unitPrice: product.unitPrice,
            status: product.status,
            subInventoryCode: product?.caseUsageSubInventoryCode || '',
            approvalStatus: product.approvalStatus,
            salesUnitOfMeasure: product.salesUnitOfMeasure,
            isLotNumber: product.isLotNumber,
          });
        }
      });

      let mutation: any = {
        orderId: inventoryDetail.orderId,
        orderExternalId: inventoryDetail.orderExternalId
          ? inventoryDetail.orderExternalId
          : inventoryDetail.externalId,
        salesRepId: inventoryDetail.salesRepId,
        accountId: inventoryDetail.accountId,
        receivingRepId: inventoryDetail.receivingRepId,
        fromSubInventoryCode: inventoryDetail.fromSubInventoryCode,
        toSubInventoryCode: inventoryDetail.toSubInventoryCode,
        salesOrganization: inventoryDetail.salesOrganization,
        status: inventoryDetail.status,
        // returnType: inventoryDetail.returnType,
      };
      if (type === 'Requesting') {
        mutation = {
          ...mutation,
          lineItems: payload,
        };
      } else {
        mutation = {
          ...mutation,
          lineItems: payload,
        };
      }
      setLoader(true);
      createUpdateInventoryReturn({
        variables: mutation,
      })
        .then((res: any) => {
          if (res && res.data && res.data.updateTransferInventory.message === 'success') {
            setTimeout(() => {
              setLoader(false);
              openSnackBar(
                NOTIFY_TYPE.SUCCESS,
                'Line items for Transfer Inventory updated successfully'
              );
            }, 1000);
            refetch();
          }
        })
        .catch(() => {
          setLoader(false);
          setTimeout(() => {
            openSnackBar(
              NOTIFY_TYPE.ERROR,
              'Error:  We are having issue updating the line items for Return Inventory'
            );
          }, 1000);
        });
    }
  };

  const handleAddParts = (): void => {
    refetch();
    setShowSearchCatalogModal(true);
  };

  const handleDragOnHand = (result: any): void => {
    if (!result.destination) {
      return;
    }
    const listItems =
      type === 'Requesting'
        ? [...inventoryDetail.lineItems]
        : [...inventoryDetail.transferredLineItems];
    const reorderedItem = listItems.splice(result.source.index, 1);
    listItems.splice(result.destination.index, 0, ...reorderedItem);
  };
  const handleCount = (event: any, data: { value: string; number: number }, product: any): void => {
    const updatedList = [...items];
    updatedList.forEach((element: any) => {
      if (
        product.productId === element.productId &&
        product.usageExternalId === element.usageExternalId &&
        product.lotNumber === element.lotNumber
      ) {
        const packContent =
          element?.salesUnitOfMeasure === 'PK' || element?.salesUnitOfMeasure === 'BX';

        const packOfContentCount = product?.packContent > 0 ? product?.packContent || 1 : 1;

        if (data.value !== '' && data.number > 0) {
          element.counterValue = data.number < MAX_QUANTITY ? data.number : MAX_QUANTITY;
          if (
            userInfo?.businessunit === 'Sports Medicine' &&
            element.productFamily === 'SPTSIMPLANTS' &&
            packContent
          ) {
            element.actualQuantity = element.counterValue * packOfContentCount;
          } else {
            element.actualQuantity = element.counterValue;
          }
          element.changed = true;
        } else {
          element.counterValue = '';
          element.changed = false;
        }
      }
    });
    setItems(updatedList);
    handleUpdates();
  };
  const handleCountalert = (
    event: any,
    data: { value: string; number: number },
    product: any
  ): void => {
    if (product?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, product);
  };
  const handleDelete = (product: any): void => {
    // empty/clear quantity, it will delete ib Backend
    let counter = 0;
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (
        i.productId === product.productId &&
        i.usageExternalId === product.usageExternalId &&
        product.lotNumber === i.lotNumber
      ) {
        i.counterValue = 0;
        i.changed = true;
        i.deleted = true;
      }
      counter += i.counterValue;
    });
    setItems(listItems || []);
    handleUpdates();
  };
  const sourceLocation = inventoryDetail?.type === 'Customer to Rep' ? 'Customer' : 'Repstock';

  const iconPath = getEventIcon(CYCLE_COUNT);
  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      {transferType !== 'Received' ? (
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          {inventoryDetail?.type !== EVENT_TRANSFER && (
            <div style={{ marginRight: '10px' }}>
              <CartComponent
                count={quantityCount}
                color="grey"
                textColor="white"
                cartTitle="Requesting"
              />
            </div>
          )}
          {inventoryDetail?.type !== EVENT_TRANSFER && transferType !== 'Received' && (
            <div style={{ marginRight: '10px' }}>
              <CartComponent
                count={quantityTransferCount}
                color="grey"
                textColor="white"
                cartTitle="Transferred"
              />
            </div>
          )}
          <Button
            iconCategory="utility"
            iconSize="small"
            className="slds-text-color_default"
            label="Add"
            iconName="add"
            iconPosition="left"
            disabled={
              (visualIndicator.toLowerCase() === 'requested' && !isUserOps) ||
              // visualIndicator.toLowerCase() === 'approved' ||
              visualIndicator.toLowerCase() === 'cancelled' ||
              visualIndicator.toLowerCase() === 'completed' ||
              visualIndicator.toLowerCase() === 'submitted' ||
              visualIndicator.toLowerCase() === 'closed' ||
              isOrder ||
              viewOnly ||
              (inventoryDetail?.type === EVENT_TRANSFER &&
                inventoryDetail?.status === 'Completed') ||
              transferType === 'Processed' ||
              (transferType === 'Failed' && !isUserOpsCsr)
            }
            style={{ fontWeight: 'bold' }}
            onClick={handleAddParts}
          />
        </ButtonGroup>
      ) : (
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          {inventoryDetail?.type !== EVENT_TRANSFER && (
            <div style={{ marginRight: '10px' }}>
              <CartComponent
                count={quantityCount}
                color="grey"
                textColor="white"
                cartTitle="Requesting"
              />
            </div>
          )}
          {inventoryDetail?.type !== EVENT_TRANSFER && transferType !== 'Received' && (
            <div style={{ marginRight: '10px' }}>
              <CartComponent
                count={quantityTransferCount}
                color="grey"
                textColor="white"
                cartTitle="Transferred"
              />
            </div>
          )}
          <Button
            iconCategory="utility"
            iconSize="small"
            className="slds-text-color_default"
            label="Approve All"
            // iconName="add"
            iconPosition="left"
            disabled={
              inventoryDetail.visualIndicator === 'Completed' ||
              (inventoryDetail?.receivingRepId !== userInfo?.id &&
                !isUserOps &&
                !isUserAsr &&
                !isUserRsm)
            }
            style={{ fontWeight: 'bold', marginRight: '10px' }}
            onClick={handleAproveAll}
          />
          <Button
            iconCategory="utility"
            iconSize="small"
            className="slds-text-color_default"
            label="Reject All"
            // iconName="add"
            iconPosition="left"
            disabled={
              inventoryDetail.visualIndicator === 'Completed' ||
              (inventoryDetail?.receivingRepId !== userInfo?.id &&
                !isUserOps &&
                !isUserAsr &&
                !isUserRsm)
            }
            style={{ fontWeight: 'bold', marginRight: '10px' }}
            onClick={handleRejectAll}
          />
          <Button
            iconCategory="utility"
            iconSize="small"
            className="slds-text-color_default"
            label="Add"
            iconName="add"
            iconPosition="left"
            disabled={
              (visualIndicator.toLowerCase() === 'requested' && !isUserOps) ||
              // visualIndicator.toLowerCase() === 'approved' ||
              visualIndicator.toLowerCase() === 'cancelled' ||
              visualIndicator.toLowerCase() === 'completed' ||
              visualIndicator.toLowerCase() === 'submitted' ||
              visualIndicator.toLowerCase() === 'closed' ||
              isOrder ||
              viewOnly ||
              (inventoryDetail?.type === EVENT_TRANSFER &&
                inventoryDetail?.status === 'Completed') ||
              transferType === 'Processed' ||
              (transferType === 'Failed' && !isUserOpsCsr)
            }
            style={{ fontWeight: 'bold' }}
            onClick={handleAddParts}
          />
        </ButtonGroup>
      )}
    </PageHeaderControl>
  );

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {isLoading && <Spinner />}
      <PageHeader
        className="events_header"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        onRenderActions={headerActions}
        variant="record-home"
        title="Inventory"
      />
      {showSearchCatalogModal && (
        <InventoryRequestAddItems
          isOpen={showSearchCatalogModal}
          refetchTransferDetails={refetch}
          handleClose={onHandleCloseSearchCatalogModal}
          inventoryReturnDetail={inventoryDetail}
          returnType={transferType}
          // selectedFilter={handleSelectedLineItems}
          //  handleFilter={handleFilter}
        />
        // {showSearchCatalogModal && (
        //   <InventoryRequestAddItems
        //     isOpen={showSearchCatalogModal}
        //     refetchTransferDetails={refetch}
        //     handleClose={onHandleCloseSearchCatalogModal}
        //     inventoryDetail={inventoryDetail}
        //     transferType={transferType}
        //   />
      )}
      <div className="order-list-container">
        <DragDropContext onDragEnd={handleDragOnHand}>
          <Droppable droppableId="items">
            {(provided: any): any =>
              type === 'Requesting' ? (
                <div className="inventory-table-container">
                  <div className={`parts-table-header ${tableClass} `}>
                    <p>Part</p>
                    <p style={{ textAlign: 'left' }}>Lot/Serial</p>
                    <p style={{ textAlign: 'left' }}>
                      {transferType === 'Received' ? 'Expiration Date' : 'Status'}
                    </p>
                    <p style={{ textAlign: 'center' }}>Qty</p>
                    <p style={{ textAlign: 'center', paddingRight: '10px' }}>Action</p>
                  </div>
                  {items?.length > 0 &&
                    items.map((lineItem: any) => {
                      return !lineItem.deleted ? (
                        <div className={`parts-table-header ${tableClass} `}>
                          <div
                            style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                          >
                            {`${lineItem.productNumber} ${
                              isOrder ? lineItem.productdescription : lineItem.productDescription
                            }`}
                            <br />
                          </div>
                          <div
                            style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                          >
                            {`${lineItem?.lotNumber || ''}`}
                          </div>
                          {transferType === 'Received' ? (
                            <div
                              style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                            >
                              {`${
                                lineItem.expirationDate
                                  ? moment(lineItem.expirationDate).format('MM/DD/YYYY')
                                  : '--'
                              }`}
                            </div>
                          ) : (
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              <EventStatusBadge
                                status={
                                  lineItem?.approvalStatus === 'No Action'
                                    ? 'New'
                                    : lineItem?.approvalStatus || 'New'
                                }
                              />
                              {lineItem.productOnHold ? (
                                <img
                                  src={holdIconPath}
                                  className="logo-image"
                                  alt="company"
                                  style={{
                                    marginLeft: '92%',
                                    width: '16px',
                                    height: '17px',
                                    position: 'relative',
                                    bottom: '52%',
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                          <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                            {/* <div className="row-control">
                              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {`${lineItem?.counterValue || ''}`}
                              </div>
                            </div> */}
                            <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                              <div className="row-control">
                                <Input
                                  id="counter-input-3"
                                  minValue={1}
                                  maxValue={
                                    Number(lineItem.availableQuantity) + Number(lineItem.quantity)
                                  }
                                  disabled={
                                    visualIndicator.toLowerCase() === 'requested' ||
                                    visualIndicator.toLowerCase() === 'cancelled' ||
                                    visualIndicator.toLowerCase() === 'completed' ||
                                    visualIndicator.toLowerCase() === 'submitted' ||
                                    visualIndicator.toLowerCase() === 'closed' ||
                                    isOrder ||
                                    viewOnly ||
                                    (inventoryDetail?.type === EVENT_TRANSFER &&
                                      inventoryDetail?.status === 'Completed') ||
                                    transferType === 'Processed' ||
                                    (transferType === 'Failed' && !isUserOpsCsr) ||
                                    transferType === 'Received'
                                  }
                                  onChange={(
                                    event: any,
                                    data: { value: string; number: number }
                                  ): void => {
                                    handleCountalert(event, data, lineItem);
                                  }}
                                  value={lineItem?.counterValue}
                                  variant="counter"
                                />
                              </div>
                            </div>
                          </div>
                          {transferType === 'Received' && (
                            <div className="vertical-align-middle" style={{ paddingLeft: '10px' }}>
                              <Combobox
                                id="combobox-readonly-single"
                                menuPosition="overflowBoundaryElement"
                                // disabled={viewOnly}
                                events={{
                                  onSelect: (_event: any, data: any): void =>
                                    handleSelectDropdownItem(data, lineItem),
                                }}
                                labels={{
                                  placeholder: 'Select',
                                }}
                                options={TRANSFER_ACTIONS_APPROVAL || []}
                                disabled={
                                  // lineItem?.approvalStatus === 'Approved' ||
                                  inventoryDetail?.visualIndicator === 'Completed' ||
                                  (inventoryDetail?.receivingRepId !== userInfo?.id &&
                                    !isUserOps &&
                                    !isUserAsr &&
                                    !isUserRsm)
                                }
                                selection={
                                  lineItem?.approvalStatus === 'Approved' ||
                                  lineItem?.approvalStatus === 'Rejected' ||
                                  lineItem?.approvalStatus === 'No Action'
                                    ? [
                                        {
                                          label:
                                            lineItem?.approvalStatus === 'Approved'
                                              ? 'Approve'
                                              : lineItem?.approvalStatus === 'Rejected'
                                              ? 'Reject'
                                              : 'No Action',
                                          value: '',
                                        },
                                      ]
                                    : []
                                }
                                variant="readonly"
                              />
                            </div>
                          )}
                          <div
                            className="vertical-align-middle"
                            style={{ paddingRight: '15px', textAlign: 'center' }}
                          >
                            <Button
                              disabled={
                                // lineItem.approvalStatus?.toLowerCase() === 'requested' ||
                                // visualIndicator.toLowerCase() === 'requested' ||
                                ((isUserAsr || isUserRsm) &&
                                  visualIndicator.toLowerCase() === 'requested') ||
                                (transferType !== 'Sent' &&
                                  visualIndicator.toLowerCase() !== 'new' &&
                                  !(isUserOps && visualIndicator.toLowerCase() === 'requested') &&
                                  lineItem.approvalStatus?.toLowerCase() === 'approved') ||
                                (visualIndicator.toLowerCase() === 'rejected' &&
                                  lineItem.approvalStatus?.toLowerCase() === 'approved') ||
                                visualIndicator.toLowerCase() === 'cancelled' ||
                                (inventoryDetail?.type === EVENT_TRANSFER &&
                                  inventoryDetail?.status === 'Completed') ||
                                inventoryDetail?.status === 'Closed' ||
                                isOrder ||
                                transferType === 'Completed' ||
                                transferType === 'Processed' ||
                                viewOnly ||
                                (transferType === 'Failed' &&
                                  !isUserOpsCsr &&
                                  lineItem &&
                                  lineItem.approvalStatus &&
                                  lineItem.approvalStatus.toLowerCase() === 'approved')
                              }
                              iconCategory="utility"
                              iconName="delete"
                              iconSize="large"
                              iconVariant="bare"
                              variant="icon"
                              onClick={(): void => handleDelete(lineItem)}
                            />
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
              ) : (
                <div className="inventory-table-container">
                  <div className={`parts-table-header ${tableClass} `}>
                    <p>Part</p>
                    <p style={{ textAlign: 'left', marginLeft: '4%' }}>Lot/Serial</p>
                    <p style={{ textAlign: 'left', marginLeft: '25px' }}>Status</p>
                    <p style={{ textAlign: 'center' }}>Requesting Qty</p>
                    <p style={{ textAlign: 'center' }}>Transferred Qty</p>
                    <p style={{ textAlign: 'center' }}>ERP Order</p>
                    <p style={{ textAlign: 'center' }}>Received</p>
                  </div>
                  {items?.length > 0 &&
                    items.map((lineItem: any) => {
                      return !lineItem.deleted ? (
                        <div className={`parts-table-header ${tableClass} `}>
                          <div
                            style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                          >
                            {`${lineItem.productNumber} ${
                              isOrder ? lineItem.productdescription : lineItem.productDescription
                            }`}
                            {lineItem?.failureReason && (
                              <span style={{ marginLeft: '10px' }} title={lineItem?.failureReason}>
                                <Icon
                                  category="utility"
                                  name="warning"
                                  size="x-small"
                                  style={{ fill: '#D2042D', marginRight: '5px' }}
                                />
                              </span>
                            )}
                          </div>
                          <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
                            {`${lineItem?.lotNumber || '--'}`}
                            {lineItem.productOnHold ? (
                              <img
                                src={holdIconPath}
                                className="logo-image"
                                alt="company"
                                style={{
                                  marginLeft: '92%',
                                  width: '16px',
                                  height: '17px',
                                  position: 'relative',
                                  bottom: '52%',
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            <EventStatusBadge status={lineItem.status} />
                            {lineItem.productOnHold ? (
                              <img
                                src={holdIconPath}
                                className="logo-image"
                                alt="company"
                                style={{
                                  marginLeft: '92%',
                                  width: '16px',
                                  height: '17px',
                                  position: 'relative',
                                  bottom: '52%',
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {`${lineItem?.requestingQuantity || ''}`}
                          </div>
                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {`${lineItem?.transferredQuantity || ''}`}
                          </div>
                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {`${lineItem?.erpOrderNumber || ''}`}
                          </div>
                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {lineItem.receivedDate
                              ? moment(lineItem.receivedDate)
                                  .utc()
                                  .format('MM/DD/YYYY')
                              : '--'}
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>
    </IconSettings>
  );
};

/** Custom Header on InventoryRequestDetailCatalog Page **/
export default InventoryRequestTable;
